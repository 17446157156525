import type { ResetPasswordForm } from '../../types';
import type { Response } from '~/src/shared/lib';
import { useApi } from '@/src/services/network';

type ApiResult = Response<string>;

export const useResetPassword = () => {
	const api = useApi();

	return async (payload: ResetPasswordForm) => {
		return (
			await api<ApiResult>('/StartRegistration/sendCodeForgotPassword/', {
				method: 'POST',
				body: payload,
			})
		).mapRight(res => res.result);
	};
};
