import type { NewPasswordForm } from '../../types';
import { useApi } from '@/src/services/network';

// TODO

export const useNewPassword = () => {
	const api = useApi();

	return async (payload: NewPasswordForm) => {
		return await api<string>('/User/changePassword/', {
			method: 'POST',
			body: payload,
		});
	};
};
