import type { PersonalForm } from '../../types';
import { useApi } from '@/src/services/network';

export const useSendPersonal = () => {
	const api = useApi();

	return async (payload: PersonalForm) => {
		return await api('/Registration/registration/', {
			method: 'POST',
			body: payload,
		});
	};
};
