import { useConfirmCode } from './codeConfirmation';
import { useLogin } from './login';
import { useNewPassword } from './newPassword';
import { useResetPassword } from './resetPassword';
import { useSendCode } from './sendCode';
import { useSendPersonal } from './sendPersonal';
import { useSendPartnerCode } from './sendPartnerCode';

export const apiMethods = {
	useLogin,
	useResetPassword,
	useNewPassword,
	useSendCode,
	useSendPartnerCode,
	useConfirmCode,
	useSendPersonal,
};
