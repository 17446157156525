import type { LoginForm } from '../../types';
import type { Response } from '~/src/shared/lib';
import { useApi } from '@/src/services/network';

type ApiResult = Response<string>;

export const useLogin = () => {
	const api = useApi();

	return async (payload: LoginForm) => {
		return (
			await api<ApiResult>('/Auth/login/', {
				method: 'POST',
				body: payload,
			})
		).mapRight(res => res.result);
	};
};
